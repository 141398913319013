import React, { useState } from "react";
import "./Pricing.css"; // You can update this as needed
import { Link } from "react-router-dom";

const PricingPage = () => {
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const faqs = [
    {
      question: "What services are included in the Basic Plan?",
      answer:
        "The Basic Plan includes a static website with up to 5 pages, a responsive design, and basic SEO setup to improve your website's visibility on search engines.",
    },
    {
      question: "Can I customize my plan with add-ons?",
      answer:
        "Yes, you can add on additional features like advanced SEO, content writing, blog integration, social media management, and eCommerce functionality to enhance your website.",
    },
    {
      question: "What payment options are available?",
      answer:
        "We accept major credit cards, bank transfers, and digital payments. Custom payment plans may be arranged if needed.",
    },
    {
      question: "Do I need to commit to a contract?",
      answer:
        "No long-term contract is required. You can select either a monthly subscription, one-time project fee, or ongoing support as needed.",
    },
    {
      question: "How long does it take to set up a new website?",
      answer:
        "Setup time depends on complexity. A basic website typically takes 1-2 weeks, while more complex sites with custom features may take 4-6 weeks.",
    },
    {
      question: "What if I need help with updates or changes?",
      answer:
        "We offer ongoing support and maintenance packages for updates, changes, and improvements. You can choose a support package or request updates as needed.",
    },
    {
      question: "Is hosting included with my website package?",
      answer:
        "Hosting is not included by default but can be added as an additional service. We can help you choose and set up the best hosting plan for your needs.",
    },
    {
      question: "Can I transfer my existing website to your services?",
      answer:
        "Yes, we can assist with transferring an existing website, including content migration, URL redirects, and SEO preservation to ensure a seamless transition.",
    },
    {
      question: "Will my website be mobile-friendly?",
      answer:
        "Absolutely! All websites we build are responsive, ensuring an optimized viewing experience on all devices, including mobile phones and tablets.",
    },
    {
      question: "Do you provide SEO services with your plans?",
      answer:
        "All plans include basic SEO to enhance your search engine visibility. Advanced SEO options, like keyword optimization, blog content, and analytics, are available as add-ons.",
    },
    {
      question: "Can I add an online store to my website?",
      answer:
        "Yes, we offer eCommerce integration for online stores. This includes product listings, shopping cart functionality, payment gateways, and inventory management.",
    },
    {
      question: "What CMS (Content Management System) do you use?",
      answer:
        "We work with various CMS options, including WordPress, Shopify, and custom CMS solutions, allowing you to easily manage and update your website’s content.",
    },
    {
      question: "Will I have full ownership of the website after completion?",
      answer:
        "Yes, you will have full ownership of your website, including the domain, hosting, and content. We ensure you have access to all necessary credentials.",
    },
    {
      question: "How do you ensure website security?",
      answer:
        "We implement security best practices, including SSL certificates, secure login protocols, regular updates, and optional backups to protect your website.",
    },
    {
      question: "Do you offer website analytics and reporting?",
      answer:
        "Yes, we can integrate Google Analytics and other tracking tools, providing insights into visitor behavior, page views, and other valuable metrics.",
    },
    {
      question: "How does the design process work?",
      answer:
        "We start with a consultation to understand your vision, followed by wireframes and mockups. Once approved, we proceed with the development and testing stages.",
    },
    {
      question: "Can I add more pages to my website in the future?",
      answer:
        "Absolutely! You can request additional pages anytime. We can either set up the pages for you or provide training so you can add them yourself.",
    },
    {
      question: "Do you offer digital marketing services?",
      answer:
        "Yes, we provide digital marketing add-ons, including social media management, content marketing, and email campaigns to help grow your online presence.",
    },
    {
      question: "What is your refund policy?",
      answer:
        "Our refund policy varies depending on the project stage. We’ll be happy to discuss your specific situation if you have concerns about your investment.",
    },
    {
      question: "Will my website be optimized for search engines?",
      answer:
        "Yes, all websites are built with SEO-friendly code. We also offer additional SEO services for in-depth optimization, including keyword research and content strategies.",
    },
    {
      question: "Do you offer training on how to use the website?",
      answer:
        "Yes, we provide training sessions to ensure you feel confident managing your website content, updating information, and using essential features.",
    },
    {
      question: "How do you handle domain registration and renewals?",
      answer:
        "We can assist with domain registration or transfer. We can also manage renewals if preferred, or guide you to manage it independently.",
    },
    {
      question: "Is there support after the website goes live?",
      answer:
        "Yes, we offer post-launch support packages for ongoing maintenance, technical assistance, and updates to keep your website running smoothly.",
    },
  ];

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index); // Toggle open/close state for FAQ
  };
  return (
    <div className="pricing-page">
      <header className="pricing-header">
        <h1>Our Pricing Plans</h1>
        <p>
          Flexible and budget-friendly plans designed to help small businesses
          grow online. From basic websites to full e-commerce solutions, we’ve
          got you covered.
        </p>
      </header>
      {/* Pricing Tiers */}
      <div className="pricing-container">
        {/* Starter Website Package */}
        <div className="pricing-card">
          <h2>Starter Website</h2>
          <p className="price">$299</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Single Page</li>
            <li>Responsive Design</li>
            <li>Basic SEO Setup</li>
            <li>Google Business Setup</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Get Started Today</button>
          </Link>
        </div>

        {/* Static Website - Basic */}
        <div className="pricing-card">
          <h2>Static Website</h2>
          <p className="price">$499</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Up to 5 Pages</li>
            <li>Responsive Design</li>
            <li>Basic SEO Setup</li>
            <li>Google Business and Analytics Setup</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Get Your Site Online</button>
          </Link>
        </div>

        {/* Dynamic Website - Standard */}
        <div className="pricing-card">
          <h2>Dynamic Website</h2>
          <p className="price">$799</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Up to 10 Pages</li>
            <li>CMS Integration (WordPress/Custom)</li>
            <li>Enhanced SEO Optimization</li>
            <li>Google Analytics and Conversion Tracking</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Build a Dynamic Site</button>
          </Link>
        </div>

        {/* E-Commerce Website - Premium */}
        <div className="pricing-card">
          <h2>E-Commerce Website</h2>
          <p className="price">$1,299</p>
          <p className="plan-duration">One-time fee</p>
          <ul className="features">
            <li>Unlimited Products</li>
            <li>Custom Shopping Cart</li>
            <li>Secure Payment Gateway</li>
            <li>Built-in SEO & Marketing Tools</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Launch Your Store</button>
          </Link>
        </div>

        {/* Full Branding & Graphic Design */}
        <div className="pricing-card">
          <h2>Branding & Graphic Design</h2>
          <p className="price">Starting at $299</p>
          <p className="plan-duration">Project-based pricing</p>
          <ul className="features">
            <li>Logo Design</li>
            <li>Business Cards</li>
            <li>Branding Kits</li>
            <li>Social Media Graphics</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Create Your Brand</button>
          </Link>
        </div>
      </div>
      {/* SEO and Additional Page Recommendations */}
      <div className="additional-section">
        <h2>Optimize Your Online Presence</h2>
        <p>
          Take advantage of our SEO and marketing options to help clients find
          you online. Explore our advanced SEO plans, starting at just $199.
        </p>
        <Link to="/seo-services">
          <button className="cta-button">Learn More About SEO</button>
        </Link>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2>Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq ${openFaqIndex === index ? "open" : ""}`}
          >
            <h3 onClick={() => toggleFaq(index)}>{faq.question}</h3>
            {openFaqIndex === index && <p>{faq.answer}</p>}
          </div>
        ))}
      </div>
      {/* Suggested New Pages */}
      <div className="suggested-pages">
        <h2>Explore More</h2>
        <p>
          Get all the tools and insights you need to grow your business with our
          resources and services.
        </p>
        <ul>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/blog">Blog - Tech Tips for Small Businesses</Link>
          </li>
          <li>
            <Link to="/testimonials">Client Success Stories</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
        </ul>
      </div>
      {/* FAQ Section */}
      {/* <div className="faq-section">
        <h2>Frequently Asked Questions</h2>

        <div className="faq">
          <h3>How can a website help my business grow?</h3>
          <p>
            A professionally built website boosts credibility, reaches new
            audiences, and builds trust with potential clients. We’ll help set
            up a site that represents your unique brand.
          </p>
        </div>

        <div className="faq">
          <h3>How does SEO work?</h3>
          <p>
            SEO, or Search Engine Optimization, improves your website's
            visibility on search engines. Our SEO services help your website
            rank higher, so customers can find you more easily.
          </p>
        </div>

        <div className="faq">
          <h3>What does the design process look like?</h3>
          <p>
            We start by understanding your brand and goals, then create a design
            tailored to your vision. Throughout the process, we offer up to 3
            revisions to ensure your complete satisfaction.
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default PricingPage;
