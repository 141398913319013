import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-text">
          <h1>InnoNative Solutions</h1>
          <h2>Empowering Businesses with Tailored Digital Strategies</h2>
        </div>
      </div>

      {/* Introduction */}
      <div className="about-section">
        <div className="about-content">
          <h2>About InnoNative Solutions</h2>
          <p>
            <strong>InnoNative Solutions</strong> is dedicated to providing
            cutting-edge digital solutions tailored to meet the unique needs of
            small businesses and local communities. Founded with a passion for
            technology and a commitment to community growth, we deliver web
            development, application development, and digital strategy services
            designed to drive success in the digital age.
          </p>
          <p>
            Our expertise in creating user-friendly, high-performance digital
            products ensures that your business thrives in an increasingly
            digital world.
          </p>
        </div>
      </div>

      {/* Who We Are */}
      <div className="about-section">
        <div className="about-content">
          <h2>Who We Are</h2>
          <p>
            InnoNative Solutions was founded by Charles Werk, a computer science
            graduate from the Fort Belknap Indian Reservation in Montana. With a
            deep understanding of the unique challenges faced by small
            businesses in local communities, Charles set out to bridge the
            digital divide by offering top-tier technology solutions tailored to
            their specific needs.
          </p>
          <p>
            Innovation, integrity, and a commitment to community development
            drive every project we undertake at InnoNative Solutions.
          </p>
        </div>
      </div>

      {/* What We Do */}
      <div className="about-section">
        <div className="about-content">
          <h2>What We Do</h2>
          <p>
            We specialize in crafting responsive websites, developing powerful
            mobile applications, and creating digital strategies that elevate
            your brand. Our services are designed to enhance your online
            presence, streamline operations, and engage your audience
            effectively.
          </p>
          <p>
            We believe in the transformative power of technology. Our mission is
            to provide local businesses with the tools they need to compete in
            the digital marketplace, fostering growth and sustainability in
            underserved communities.
          </p>
        </div>
      </div>

      {/* Who We Serve */}
      <div className="about-section">
        <div className="about-content">
          <h2>Who We Serve</h2>
          <p>
            Our clients range from small businesses in Montana to community
            organizations looking to expand their digital footprint. We
            understand the unique challenges these groups face and offer
            customized solutions that drive results.
          </p>
          <p>
            <strong>Case Study:</strong> Read about how we helped a local
            business increase their online visibility by 150% through a tailored
            website redesign and SEO optimization.{" "}
          </p>
          <a href="/my-work" className="cta-button">
            Case Study
          </a>
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="about-section">
        <div className="about-content">
          <h2>Why Choose Us</h2>
          <p>
            InnoNative Solutions stands out for our deep community roots,
            personalized approach, and commitment to delivering results. We’re
            not just another tech company; we’re a partner in your business’s
            success.
          </p>
          <p>
            By choosing us, you gain access to expert knowledge, innovative
            solutions, and a team dedicated to helping your business thrive.
          </p>
        </div>
      </div>

      {/* Call to Action */}
      <div className="cta-section">
        <h3>Ready to take your business to the next level?</h3>
        <a href="/contact" className="cta-button">
          Contact us today for a free consultation.
        </a>
      </div>
    </div>
  );
};

export default About;
