import React from "react";
import "./AboutSnippet.css";

const AboutSnippet = () => {
  return (
    <section className="about-snippet">
      <h2>
        Transforming Your Ideas into Digital Reality with{" "}
        <b>Innonative Solutions</b>
      </h2>
      <p>
        At Innonative Solutions LLC, we believe your success is our mission.
        Founded by Charles Werk, we specialize in crafting custom websites,
        mobile apps, and software solutions that not only look great but drive
        results. Whether you're a small business looking to grow or a community
        aiming to expand your digital footprint, we're here to turn your vision
        into reality.
      </p>
      <p>
        Partner with us to create digital experiences that truly connect with
        your audience. From responsive web design to mobile app development, we
        bring innovative technology and personalized service to every project.
        Let’s build something great together.
      </p>
    </section>
  );
};

export default AboutSnippet;
