import React from "react";
import "./CTA.css";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <section className="cta">
      <h1>Ready to Create Something That Resonates?</h1>
      <p>
        At Innonative Solutions LLC, we don't just build digital platforms—we
        partner with you to bring your vision to life. Whether it's a custom
        mobile app, a website that feels like home, or a digital solution that
        tells your story, we're here to help you create meaningful connections
        and drive your business forward.
      </p>
      <Link to="/contact" className="cta-button">
        Start Your Story with Us
      </Link>
    </section>
  );
};

export default CTA;
