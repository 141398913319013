import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faMobileAlt,
  faPalette,
  faLightbulb,
} from "@fortawesome/free-solid-svg-icons";
import "./Services.css";

const Services = () => {
  return (
    <section className="services">
      <h1>Our Services</h1>
      <div className="services-container">
        <div className="service">
          <FontAwesomeIcon icon={faCode} className="service-icon" />
          <h3>Web Development</h3>
          <p>
            Creating responsive and modern websites to help you reach your
            audience effectively.
          </p>
          <Link to="/pricing" className="service-btn">
            Learn More
          </Link>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
          <h3>Mobile Apps</h3>
          <p>
            Developing mobile applications that provide a seamless user
            experience on any device.
          </p>
          <Link to="/pricing" className="service-btn">
            Learn More
          </Link>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faPalette} className="service-icon" />
          <h3>Graphic Design</h3>
          <p>
            Designing visually appealing graphics to enhance your brand’s
            presence and communication.
          </p>
          <Link to="/pricing" className="service-btn">
            Learn More
          </Link>
        </div>
        <div className="service">
          <FontAwesomeIcon icon={faLightbulb} className="service-icon" />
          <h3>Consulting</h3>
          <p>
            Offering expert advice to help you leverage technology for better
            outcomes.
          </p>
          <Link to="/pricing" className="service-btn">
            Learn More
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Services;
